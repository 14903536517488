var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Hidrocarburos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Administrativo")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Gestión Bonos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Programaciones")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card",staticStyle:{"display":"inherit"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_vm._m(1),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"calendarBonos"}},[_c('section',{staticClass:"col-12"},[_c('div',{staticClass:"card-header pt-2 pb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-11"}),_c('div',{staticClass:"col-md-1"},[_c('div',{staticClass:"btn-group float-right"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-programacion"},on:{"click":function($event){_vm.abrirModal('Crear', null) && _vm.limpiar()}}},[_c('i',{staticClass:"fas fa-plus"})])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-11"},[_vm._m(2),_c('div',{staticClass:"mt-0"},[_c('v-select',{staticClass:"form-control form-control-sm p-0 col-md-5",class:_vm.$store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',attrs:{"placeholder":"Sitio","label":"nombre","options":_vm.listasForms.sitios},model:{value:(_vm.sit),callback:function ($$v) {_vm.sit=$$v},expression:"sit"}})],1),_c('div',{staticClass:"mt-3"},[_c('button',{staticClass:"btn btn-sm bg-navy p-1 col-md-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.getCalendar()}}},[_vm._v(" Filtrar ")])])])])]),_c('div',{staticClass:"card-body p-0"},[_vm._m(3),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('FullCalendar',{attrs:{"options":_vm.calendarOptions}})],1)]),_c('div',{staticStyle:{"display":"none"}},[(
                              _vm.$store.getters.can(
                                'hidrocarburos.bonosProgramacion.edit'
                              )
                            )?_c('button',{ref:"detalle_programacion",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-programacion"}}):_vm._e()])])])]),_c('div',{staticClass:"tab-pane",attrs:{"id":"tablaBonos"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-0"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm"},[_c('thead',{attrs:{"align":"center"}},[_c('tr',[_c('th',{staticClass:"text-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Sitio")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filtros.nSitio),expression:"filtros.nSitio"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","placeholder":"Sitio"},domProps:{"value":(_vm.filtros.nSitio)},on:{"change":function($event){return _vm.getIndex()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filtros, "nSitio", $event.target.value)}}})]),_c('th',{staticClass:"text-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Sitio Destino")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filtros.sitio_destino),expression:"filtros.sitio_destino"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","placeholder":"Sitio Destiono"},domProps:{"value":(_vm.filtros.sitio_destino)},on:{"change":function($event){return _vm.getIndex()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filtros, "sitio_destino", $event.target.value)}}})]),_c('th',{staticClass:"text-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Tipo Bono")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:_vm.$store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',attrs:{"placeholder":"Tipo Bono","label":"descripcion","options":_vm.listasForms.tipos_bonos},on:{"input":function($event){return _vm.selectTiposBonos()}},model:{value:(_vm.obj_tipo_bono),callback:function ($$v) {_vm.obj_tipo_bono=$$v},expression:"obj_tipo_bono"}})],1),_c('th',{staticClass:"text-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha Inicial")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filtros.fecha_ini),expression:"filtros.fecha_ini"}],staticClass:"form-control form-control-sm",attrs:{"type":"date"},domProps:{"value":(_vm.filtros.fecha_ini)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.filtros, "fecha_ini", $event.target.value)},function($event){return _vm.getIndex()}]}})]),_c('th',{staticClass:"text-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha Final")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filtros.fecha_fin),expression:"filtros.fecha_fin"}],staticClass:"form-control form-control-sm",attrs:{"type":"date"},domProps:{"value":(_vm.filtros.fecha_fin)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.filtros, "fecha_fin", $event.target.value)},function($event){return _vm.getIndex()}]}})]),_c('th',{staticClass:"text-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Temporada")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:_vm.$store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',attrs:{"placeholder":"Temporada","label":"descripcion","options":_vm.listasForms.temporadas},on:{"input":function($event){return _vm.selectTemporada()}},model:{value:(_vm.obj_temporada),callback:function ($$v) {_vm.obj_temporada=$$v},expression:"obj_temporada"}})],1),_c('th',{staticClass:"text-center"},[_c('label',{attrs:{"for":""}},[_vm._v("Tipo Ruta")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:_vm.$store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',attrs:{"placeholder":"Tipo Ruta","label":"descripcion","options":_vm.listasForms.tipos_ruta},on:{"input":function($event){return _vm.selectTipoRuta()}},model:{value:(_vm.obj_tipo_ruta),callback:function ($$v) {_vm.obj_tipo_ruta=$$v},expression:"obj_tipo_ruta"}})],1),_c('th',{staticClass:"pb-3"},[_vm._v("Monto")]),_c('th',[_c('label',{attrs:{"for":"estado"}},[_vm._v("Estado")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filtros.estado),expression:"filtros.estado"}],staticClass:"form-control form-control-sm p-0",class:_vm.$store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',attrs:{"placeholder":"Estado"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.filtros, "estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getIndex()}]}},[_c('option',{attrs:{"value":"0"}},[_vm._v("Todos...")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Pendiente")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Autorizado")])])]),_c('th',[_vm._v("Acción")])])]),_c('tbody',_vm._l((_vm.detProgramacionBonos.data),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.sitio ? item.sitio.nombre : "")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.sitio_destino ? item.sitio_destino.nombre : "")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.nTipoBono)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.fecha_ini)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.fecha_fin)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.nTemporada)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.nTipoRuta)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.nMonto ? "$ " + parseFloat(item.nMonto).toLocaleString( "es-ES" ) : "---")+" ")]),_c('td',{staticClass:"text-center"},[_c('span',{class:item.estado == 1
                                      ? 'badge shadow badge badge-pill badge-warning'
                                      : item.estado == 2
                                      ? 'badge shadow badge badge-pill badge-success'
                                      : ''},[_vm._v(" "+_vm._s(item.nEstado ? item.nEstado : "---")+" ")])]),_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-sm bg-navy",attrs:{"type":"button"},on:{"click":function($event){return _vm.Editar(item)}}},[_c('i',{staticClass:"fas fa-edit"})])])])}),0)])]),_c('div',{staticClass:"card-footer"},[(_vm.detProgramacionBonos.total)?_c('div',{staticClass:"float-left"},[_c('p',[_vm._v(" Mostrando del "),_c('b',[_vm._v(_vm._s(_vm.detProgramacionBonos.from))]),_vm._v(" al "),_c('b',[_vm._v(_vm._s(_vm.detProgramacionBonos.to))]),_vm._v(" de un total: "),_c('b',[_vm._v(_vm._s(_vm.detProgramacionBonos.total))]),_vm._v(" Registros ")])]):_c('div',{staticClass:"float-left"},[_vm._m(4)]),_c('pagination',{attrs:{"data":_vm.detProgramacionBonos,"limit":5,"align":"right"},on:{"pagination-change-page":_vm.getIndex}})],1)])])])])])])])])])]),_c('ProgramacionBonoModal',{ref:"ProgramacionBonoModal"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"m-0"},[_vm._v("Programaciones")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-calendarBonos","data-toggle":"tab","href":"#calendarBonos"}},[_vm._v("Calendario")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"tab-tablaBonos","data-toggle":"tab","href":"#tablaBonos"}},[_vm._v("Tabla")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"sit"}},[_c('h5',[_vm._v("Sitio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group",staticStyle:{"text-align":"left"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"badge badge-dark"},[_vm._v("No hay registros para mostrar")])])
}]

export { render, staticRenderFns }